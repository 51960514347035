.deliveryInfoWrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .tatInfoWrap {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 22px;
            height: 100%;
            min-height: 24px;

            svg {
                max-width: 100%;
                vertical-align: top;
            }
        }

        .msgTat {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 1px 6px;
            color: #20262E;
            white-space: nowrap;
        }
    }

    &.express,
    &.today,
    &.tomorrow {
        .tatInfoWrap {
            border-radius: 4px;
            border: 1px solid #EF7B4C;
            background: transparent;

            .icon {
                border-radius: 3px 0px 0px 3px;
                background: #EF7B4C;
                padding: 3px;
            }
        }

        .timerWrap {
            margin-left: 5px;
            display: flex;
            flex-wrap: nowrap;
            .msgTag,
            .timerInfo {
                font-size: 12px;
                font-style: normal;
                line-height: normal;
            }

            .msgTag {
                color: #20262E;
                font-weight: 400;
            }

            .timerInfo {
                color: #D1342A;
                font-weight: 500;
                margin-left: 2px;
            }
        }

    }

    &.normal {
        .tatInfoWrap {
            .icon {
                padding: 3px 0px;
            }
        }

        .timerWrap {
            display: none;
        }

    }

    &.flex_column {
        flex-direction: column;

        .timerWrap {
            margin-left: 0px;
            width: 100%;
            margin-top: 6px;
        }
    }
}