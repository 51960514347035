@import 'static/css/global/global.scss';

$mobile-breakpoint: 767px;

.actualu-cart-menu {
    box-shadow: none !important
}

.cart-item {
    padding: 0;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 30px #0000001A;
    border-radius: 12px;
    opacity: 1;
    margin: 10px 0px;
    // background: #ffe2dd;

    @media screen and (min-width: 1024px) {
        position: relative;
        padding: 0;
        border-radius: 12px;

    }

    // &:not(:last-of-type) {
    //     border-bottom: 1px solid #1B285C33;
    // }
    .cartitem-list {
        padding: 21px 0 !important;

        .cart-image {
            .cart-image-side-menu {
                padding: 5px;
                height: 72px;
                width: 79px;
                background-color: #F2F3F5;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                img {
                    height: 100%;
                    width: auto !important;
                    mix-blend-mode: multiply;
                }
            }
        }
    }

    @media (max-width: $mobile-breakpoint) {
        padding: 10px 0;
        margin: 0;
    }

    &.isCombo {
        border: none;
        // padding: 0 12px 16px;

        @media screen and (min-width: 1024px) {
            margin: 0px !important;
            box-shadow: none !important;
        }

        .cart-item__details {
            .delete-cta {
                right: -7px;
            }
        }
    }

    .plus_image {
        width: 34px;
        height: 95px;
        position: relative;
        z-index: 2;
    }

    .combo-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .left {
            .cart-wishlist {
                background-color: transparent;
                align-items: center;
                display: flex;
                gap: 8px;
                border: none;
            }
        }
    }

    .cart-item__details {
        display: flex;
        cursor: pointer;
        position: relative;
        background-color: #FFF;

        @media (max-width: $mobile-breakpoint) {
            padding: 0 10px;
        }

        .cart-item__image {
            display: inline-block;
            width: 186px;
            min-width: 186px;
            // height: 100%;
            overflow: hidden;
            // background-color: $img-bg;
            border-radius: 10px;
            // background-color: #ffd6cf !important;

            @media screen and (min-width: 1024px) {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                width: 186px !important;
                // height: 186px !important;
                min-width: 186px !important;
                padding: 17px;
            }

            @media (max-width: 1024px) {
                width: 115px !important;
                min-width: 115px !important;
                height: 115px !important;
                // background-color: #ffd6cf !important;
                border-radius: 4px !important;
                // padding: 9px 24px;
            }

            img {
                width: 100%;
                height: 100%;
                white-space: nowrap;
                mix-blend-mode: darken;
                border-radius: 10px;

                @media screen and (min-width: 1024px) {
                    height: auto;
                }

                @media screen and (max-width: 1024px) {
                    border-radius: 10px;
                    width: auto;

                }
            }
        }


        .cart-item__quantity-freebie {
            position: absolute;
            bottom: 35px;
            z-index: 99;
            width: 60% !important;

            @media screen and (min-width: 1024px) {
                top: 300px;
                bottom: auto;
                z-index: 5;
            }
        }

        .extra-top {
            @media screen and (min-width: 1024px) {
                top: 375px;
                left: 183px;
            }
        }


        .cart-item__quantity {
            margin-left: auto;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            gap: 50%;

            @media screen and (min-width: 1024px) {
                flex-direction: row;
                gap: 17px;
                justify-content: space-between;
                align-items: center;
                width: 100%;

            }

            .verified {
                margin-left: auto;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                background-color: #4C9F66;
                border-radius: 11px;
                color: #fff;
                width: 94px;
                height: 24px;
            }
        }

        .cart-item__desc {

            // width: calc(100% - 330px);

            @media screen and (min-width:1024px) {
                width: 100%;
                padding: 24px 10px 24px 40px;

            }

            @media screen and (max-width:1024px) {
                padding-left: 7px;
                max-width: 100%;
                // width: calc(100% - 122px);
            }

            .cart-item__quantity {
                .hk-elements--quantitySelector {
                    height: 30px;

                    #decrement,
                    #increment,
                    #quantity {
                        height: 30px;
                    }

                    #quantity {
                        width: 50px;
                    }
                }
            }

            .cart-item__name-desktop {
                display: flex;
                align-items: center;
                @include fontStyle($fontFamily-default !important, 16px !important, 500, 20px !important, 0px, #20262E);
                width: 90%;

            }

            .cart-item__name-desktop,
            .cart-item__name {
                margin-bottom: 10px;
                display: block;

                @media screen and (max-width:768px) {
                    margin-bottom: 3px;
                }
            }

            .cart-item__name-desktop,
            .cart-item__name,
            .cart-item__name * {
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
                font-family: $font-family-barlow;
                color: $header-background;

                @media screen and (max-width:1024px) {
                    font-size: 14px;
                    line-height: 20px;
                    color: #20262E;
                    max-width: 90%;
                }

                .cart-item__flavour {
                    color: $black-mb;
                    font-weight: 600; // color: #0085CB;

                    @media screen and (max-width: 1024px) {
                        @include fontStyle($font-family-barlow, 14px, 500, 20px, 0px, #20262E);
                        padding-bottom: 4px;
                    }

                    @media screen and (min-width: 1024px) {
                        @include fontStyle($font-family-barlow !important, 16px !important, 500, 20px !important, 0px, #20262E);

                    }
                }
            }

            .cart-item__price {
                @mixin priceFont {
                    @include fontStyle($font-family-barlow, 24px, 600, 31px, 0px, $header-background);

                    @media (max-width: 768px) {
                        @include fontStyle($font-family-barlow, 14px, 600, 18px, 0px, $header-background);
                    }
                }

                display: flex;
                column-gap: 10px;
                align-items: center;

                @media (max-width: $mobile-breakpoint) {
                    column-gap: 5px;
                }

                .cart-item__offer-price {
                    @include fontStyle($font-family-barlow , 18px, 600, 12px, 0px, #20262E);


                    @media screen and (max-width : 1024px) {
                        @include fontStyle($font-family-barlow , 16px, 600, 20px, 0px, #20262E);
                        padding-right: 4px;
                    }
                }

                .cart-item_total-price {
                    text-decoration: line-through;
                    @include fontStyle($fontFamily-default, 18px, 500, 12px, 0px, #20262E);


                    @media screen and (max-width : 1024px) {
                        @include fontStyle($fontFamily-default, 14px, 500, 12px, 0px, #20262E);
                        padding-right: 4px;

                    }
                }

                .cart-item__discount {
                    @include fontStyle($font-family-barlow, 18px, 500, 12px, 0px, #51B279);
                    white-space: nowrap;
                    color: #51B279;

                    @media (max-width: $mobile-breakpoint) {
                        @include fontStyle($font-family-barlow, 14px, 500, 12px, 0px, #51B279);
                        color: #01A685;
                    }
                }
            }

            .cart-item__date {
                @include fontStyle($font-family-barlow, 14px, 400, 18px, 0px, #20262E);
                margin-top: 6px;
                display: flex;
                align-items: center;

                @media (min-width: 1024px) {
                    margin-top: 5px;
                    @include fontStyle($fontFamily-default, 14px, 500, 20px, 0px, #20262E);

                    .delivered_icon {
                        margin-right: 6px;
                        max-width: 20px
                    }

                    .delivered_date {
                        @include fontStyle($font-family-barlow, 14px, 500, 20px, 0px, #20262E);

                    }
                }

                @media (max-width: 1024px) {
                    margin-top: 3px;
                    @include fontStyle($font-family-barlow, 12px, 400, 14px, 0px, #20262E);

                    .delivered_icon {
                        margin-right: 3px;
                        max-width: 20px;
                    }

                    .delivered_date {
                        font-weight: 600;
                    }
                }
            }

            .bxgy-off {
                position: relative;
                right: 33%;
                // {
                //    // display: flex;
                // }
            }

            .mb-cache-value {
                float: none;
                display: flex;
                align-items: center;
                margin-top: 17px;
                @include fontStyle($fontFamily-regular, 14px, 400, 20px, 0px, #20262E);

                @media (max-width: 1024px) {
                    margin-top: 9px;
                    @include fontStyle($fontFamily-regular, 12px, 500, 20px, 0px, #20262E);
                }

                img {
                    margin-right: 5px;
                }
            }

            .cart-wishlist {
                display: flex;
                border: transparent;
                background: transparent;
                margin-top: 12px;
                gap: 13px;

                span {
                    font-family: $font-family-barlow;
                }
            }
        }

        .delete-combo-icons {
            right: 10px !important;
            top: -5px;

            @media screen and (min-width: 1024px) {
                top: 15px;
                right: 10px !important;
            }
        }

        .delete-cta {
            margin-left: auto;
            margin-right: 0;
            padding: 0px 2.5px;
            position: absolute;
            right: 5px;

            @media screen and (min-width: 1024px) {
                right: 15px;
                top: 15px;

                img {
                    width: 21.91px;
                    height: 18.99px;
                }
            }
        }
    }

    .freebie_item {
        position: relative;
        bottom: 0px;
        // border-top: 1px solid #EDEEF2;
        margin-top: 16px;
        // padding-top: 16px;

        .bxgy-off {
            .apply-bxgy {
                display: flex;
            }
        }
    }

    .applyBogo {
        display: flex;
        align-items: center;
        gap: 9px;

        .bogo {
            background-color: #fff;
        }

        a {
            @include fontStyle($fontFamily-default, 14px, 500, 14px, 0px, #001D4296);
            text-decoration: underline;

            @media(max-width: $mobile-breakpoint) {
                font-size: 12px;
            }
        }
    }

    .bxgy-off {
        span {

            // display: flex;
            .freebie_image {
                background: #ffff;
                margin: 6px;
            }

            .freeBIcon {
                width: 186px;
                height: 186px;
                border-radius: 4px;
                background-color: inherit;
                mix-blend-mode: multiply;
            }

            .freebie_text {
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            div {
                .offer-applied {
                    color: #001D42;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 500;

                }
            }
        }
    }

    .bogo {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #001D42;
        @include fontStyle($fontFamily-default, 17px, 500, 24px, 0px, $color-primary);

        @media (max-width: $mobile-breakpoint) {
            padding: 5px 8px;
            @include fontStyle($fontFamily-default, 12px, 500, 13px, 0px, $color-primary);
        }
    }

    .cart-page-variants-list {
        .bogoPlus {
            display: inline-block;
        }

        .middle_bogo {
            margin-left: 40px;
        }

        .line-item {
            .variant-desc {
                .wishlist--cart--item-msg {
                    padding: 10px;

                    .remove_offer {
                        color: #FF000079;
                        text-decoration: underline;
                        padding-left: 9px;
                    }
                }

                .bogo {
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #001D42;
                    color: #fff;
                    background: #001D42;
                }

                .bogocart {
                    display: flex;
                    justify-content: flex-end;
                    border-top: 1px solid;
                    border-bottom: 1px solid #a9a6a6b8;
                    border-top: 1px solid #a9a6a6b8;
                }
            }

        }
    }

    .special-offer-header {
        background: transparent url(https://static1.hkrtcdn.com/gritzo/static/media/cart/combo-header.svg) no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 45px;

        @media screen and (min-width: 1024px) {
            // To do better
            position: relative;
            top: 0px;
            left: 0px;
            width: 100%;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;

            .left {
                display: flex;
                align-items: center;

                .text {
                    padding-left: 4px;
                    white-space: nowrap;
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .right {
                .saving {
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0;
                    color: rgba(8, 10, 8, 0.7);
                    white-space: nowrap;
                    color: #01a685;
                    padding: 0px 6px;
                }
            }
        }

        &.mob {
            position: relative;
            top: -10px;
        }
    }
}

.plus_image {
    width: 34px;
    height: 95px;
    position: relative;
    z-index: 2;
}

.cart-item__quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none !important;
    display: inline-block !important;
    width: auto !important;

    @media screen and (min-width: 1024px) {
        margin: 0 0 0 210px !important;
    }

    @media screen and (max-width: 1024px) {
        margin: 0px !important;

    }

    .cart-wishlist {
        border: transparent;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #20202033;
        color: #20262E;
        font-weight: 700;
        padding: 5px 20px;
        box-shadow: 0 2px 6px #0000001C;
        // margin-left: 10px;
        margin: 0px 5px;
        width: 60%;

        @media screen and (min-width: 1024px) {
            margin: 0px 0px !important;
            border: 1px solid #20202033 !important;
            padding: 6.5px 20px;


        }

        @media screen and (max-width: 1024px) {
            @include fontStyle($font-family-barlow, 12px, 500, 20px, 0px, #20262E);

        }

        span {
            @media screen and (min-width: 1024px) {
                @include fontStyle($font-family-barlow !important, 14px, 500, 25px, 0px, #20262E);
            }

            @include fontStyle($font-family-barlow, 12px, 500, 20px, 0px, #20262E);
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #d1d4de;
        margin: 10px 10px 0;
        padding: 0px 0 0 0;
    }
}

.cart_item_tat {
    margin-top: 10px;

    &.mweb {
        display: flex;
        width: 100%;
    }
}