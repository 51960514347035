@import 'static/css/global/global.scss';

.plus-icon-side-menu{
    bottom: 14px !important;
    img{
        width: 15px !important;
        height: 15px !important;
    }
}

.plus_icon{
    height: 23px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 8px 0;
    img{
        width: 22px;
        height: 95px;
        margin-top: -1%;
        margin-left: 11px;
        top: 20px;
        @media (max-width: 767px){
            width: 15px!important;
            height: 15px!important;
            top: 8px;
            margin-left: 0px;
        }
    }
    p.text{
        @include fontStyle($fontFamily-default, 17px, 400, 24px, 0px, $color-primary);
        @media (max-width: 767px) {
            @include fontStyle($fontFamily-default, 12px, 400, 13px, 0px, $color-primary);
        }
    }
}