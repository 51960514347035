@import 'static/css/global/global.scss';


.confirmBox {
    top: 0;
    left: 0;
    right: 0;
    background-color: #2D384499;
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;

    @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .confirmBox-container {
        -webkit-transform: none;
        -moz-transform: none;
        transform: none;
        background: #fff;
        border-radius: 6px;

        @media screen and (max-width: 1024px) {
            position: absolute;
            width: 100vw;
            top: auto;
            left: 0;
            bottom: 0;
            border-radius: 20px 20px 0px 0px;

        }

        .heading {
            display: flex;
            justify-content: center;
            background-color: #F1F2F3;
            padding: 17px 16px;
            border-radius: 20px 20px 0px 0px;
            position: relative;

            @media screen and (max-width: 1024px) {
                justify-content: space-between;
            }

            span {
                @include fontStyle($font-family-barlow, 16px, 400, 26px, 0, #000000);
            }

            img {
                cursor: pointer;
                @media screen and (min-width: 1024px) {
                    position: absolute;
                    right: 10px;
                }
            }
        }

        .main-container {
            .bottom-container {
                display: flex;
                padding: 15px 28px;
                align-items: center;

                img {
                    width: 75px;
                    height: 74px;

                    @media screen and (max-width: 1024px) {
                        width: auto;
                        margin: 0 auto;

                    }
                }

                .text {
                    @include fontStyle($font-family-barlow, 14px, 400, 18px, 0, #161616);
                    width: 65%;
                    padding: 20px 10px;

                }

                @media screen and (max-width: 1024px) {
                    justify-content: space-between;
                    padding: 10px;

                }
            }

            .confirmBox-btn {
                display: flex;
                justify-content: space-between;
                padding: 15px 28px;

                .remove {
                    @include fontStyle($font-family-barlow, 12px, 400, 20px, 0, #20262E);
                    box-shadow: 0px 2px 6px #0000001C;
                    border: 1px solid #20202033;
                    border-radius: 4px;
                    width: calc(50% - 6px);
                    background-color: #FFFFFF;
                    padding: 10px 20px;
                }

                .wishlist {
                    @include fontStyle($font-family-barlow, 12px, 400, 14px, 0, #FFF);
                    background-color: #002140;
                    border-radius: 2px;
                    border: 1px solid #002140;
                    width: calc(50% - 6px);
                }
            }
        }
    }
}