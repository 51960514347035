@import 'static/css/global/global.scss';

.quantitySelector {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hk-elements--quantitySelector {
        display: flex;
        border: 1px solid #D2D2D2;
        height: 40px;
        border-radius: 8px;
        padding: 0;
        -moz-box-align: center;
        align-items: center;
        width: 134px;
        margin-left: 13px;
        margin-top: 10px;
        @media screen and (max-width: 1024px) {
            border-radius: 6px;
            width: 100px;
        }

        .decrementCls {
            // background: #ffffff;
            border-radius: $border-radius-2;
            width: 32px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            // span {
            //     display: inline-block;
            //     width: 10px;
            //     height: 3px;
            //     background: #1d273e;
            //     vertical-align: middle;
            // }
        }

        .quantityCls {
            width: 56px;
            // background: #ffffff;
            @include fontStyle($font-family-barlow, 20px, 500, 25px, 0px, #002140);
            text-align: center;

            @media screen and (min-width: 1024px) {
                padding: 6px 0px;
            }

            @media screen and (max-width: 1024px) {
                @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, #002140);
            }
        }

        .incrementCls {
            color: #ffffff;
            // background: #000;
            border-radius: $border-radius-2;
            width: 32px;
            height: 40px;
            text-align: center;
            line-height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include fontStyle($fontFamily-default, 25px, $bold, 28px, 0px, #ffffff);

            .plus {
                color: #fff;
                font-weight: 700;
                font-size: 18px;

                // svg{
                //     filter:  brightness(0) invert(1);
                // }
            }

            @media screen and (min-width: 1024px) {
                height: 38px;
            }

        }
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 2px;
        padding-top: 2px;

        .hk-elements--quantitySelector {
            height: 38px;
            margin-top: 18px;
            width: 85px;

            .decrementCls {
                height: 30px;
                line-height: 27px;
            }

            .quantityCls {
                width: 51px;
                line-height: 27px;
            }

            .incrementCls {
                height: 27px;
                line-height: 27px;


            }
        }
    }
}