@import "../../../static/css/global/global.scss";

.side-menu-actual{
  @media screen and (min-width: 1024px) {
    box-shadow: none !important;
    margin-top: 0px !important;
  }
}

.cart-pack-item {
  padding: 0;
  position: relative;
  border-radius: 4px;

  @media screen and (max-width:1024px) {
    // box-shadow: none !important;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1019607843);
  }

  @media screen and (min-width: 1024px) {
    background-color: #FFF;
    margin-top: 14px;
    box-shadow: 0px -3px 30px #0000001a;
  }

  .combo-item-from-side-menu{
    padding: 5px 0px !important;
  }

  .cartPackChild {
    border-radius: 4px;

    @media screen and (min-width: 1024px) {
      padding: 0px 16px 16px 16px;
      box-shadow: 0px 3px 20px #3941491a;

    }

    .combo-product-tag-side-menu{
      top: 10px !important;
      left: 0px !important;
    }

    .combo-product-tag {
      position: absolute;
      top: -5px;
      z-index: 5;

      @media screen and (max-width: 1024px) {
        left: 15px;
      }

      @media screen and (min-width: 1024px) {
        left: 25px;
      }

      .combo-product-tag-img {
        width: 24px;

        @media screen and (min-width: 1024px) {
          width: 28px;
          height: 58px;

        }
      }

      .combo-product-tag-ttl {
        @include fontStyle($font-family-barlow, 12px, 500, 20px, 0px, #FFFFFF);
        transform: rotate(270deg);
        position: absolute;
        top: 14px;
        left: -12px;
      }
    }

    .combo-header {
      position: relative;
      left: -16px;

      @media screen and (max-width: 1024px) {
        left: 0px;
        background-image: linear-gradient(#4192DF 92%, #2680EB 8%);
        border-radius: 4px 4px 0px 0px;
        height: 37px;
      }

      .msg-img{
        height: 20px;
        width: 20px;
        margin-right: 8.9px
      }

      .msg{
        @include fontStyle($font-family-barlow, 16px, 500, 38px, 0px, #FFFFFF);
      }

      img {
        width: 105.5%;
        height: 40px;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        @media screen and (max-width:768px) {
          height: 38px;
        }
      }

      .heading {
        position: absolute;
        top: 0px;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 12px;
        align-items: center;

        .left {
          display: flex;
          align-items: center;
          gap: 6px;

          img.percent {
            height: 20px;
          }

          .text {
            white-space: nowrap;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 600;
          }
        }

        .right {
          .text {
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 600;
          }

          .saving {
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0;
            color: rgba(8, 10, 8, .7);
            white-space: nowrap;
            color: #01a685;
            padding: 0px 6px;
          }
        }
      }
    }

    .total-for-pack-with-freebie{
      @media screen and (min-width: 1024px) {   
        border-top: 1px solid rgba(27, 40, 92, 0.2);
        padding-top: 16px;
      }
    }

    .total-for-pack {
      text-align: center;
      @include fontStyle($font-family-barlow, 16px, 500, 18px, 0px, #20262E);
      .total-for-pack-amnt{
         @include fontStyle($font-family-barlow, 16px, 500, 18px, 0px, #20262E);
      }

      @media screen and (max-width: 1024px) {
        // border-top: 1px solid #E9EAEE;
        padding-bottom: 16px;
        
      }

      @media screen and (min-width:1024px) {
        margin-top: 16px;
      }

    }
    .cartPlus-combo {
      position: relative;
      .cart-item {
        padding: 0px;
      }
      .cart-item {
        padding: 16px 12px;
        padding-top: 0px;

        @media screen and (max-width:768px) {
          padding: 19px 12px;
          position: relative;
          top: -4px;
        }
      }
    }

    .combo-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .left {
        .cart-wishlist {
          background-color: transparent;
          align-items: center;
          display: flex;
          gap: 8px;
          border: none;
        }
      }
    }

    .side-menu-cart{
      padding: 0 0px !important;
    }

    .freebie-div {
      padding: 0 15px;
      line-height: 20px;

      span.cart-freebie {
        padding: 0;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}