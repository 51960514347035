@import 'static/css/global/global.scss';

.bogo__container{
    .cart-item{
        box-shadow: none !important;
        border: 0;
        padding-bottom: 0;
    }
    
    .bogocart{
        display: flex;
        // border-top: 1px solid;
        // border-bottom: 1px solid #a9a6a6b8;
        // border-top: 1px solid #a9a6a6b8;
        .quantitySelector{
            border: 0;
            @media(max-width:767px){
                margin-left: 132px;
            }
        }
    }
}

.bogo__cta{
    padding:10px;
    .remove_offer{
        text-decoration: underline;
        padding-left: 9px;
        @include fontStyle($fontFamily-default, 14px, 500, 14px, 0px, #FF000079);
        @media(max-width: 768px){
            font-size: 12px;
        }
    }

    .bogo{
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #001D42;
        color: #fff;
        background: #001D42;
    }
}
