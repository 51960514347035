@import 'static/css/global/global.scss';

.freebie {
    &__list {
        margin-top: -5px;
    }

    &__container {
        display: flex;
        // padding: 5px 0;
        // background-color: #fff;
        column-gap: 20px;

        @media(max-width: 767px) {
            column-gap: 10px;
            padding: 5px 10px;
        }
    }

    &__container-cart-level {
        padding: 20px;
        box-shadow: 0px -3px 30px rgba(0, 0, 0, 0.1019607843);

        @media screen and (max-width: 1024px) {
            padding: 10px;
            border-radius: 10px;
        }
    }

    &__img-container-cart-level {
        height: 130px !important;
        width: 145px !important;

        @media screen and (max-width: 1024px) {
            height: 115px !important;
            width: 115px !important;
            padding: 0px !important;
        }
    }

    &__details-cart-level {
        margin-bottom: 0px !important;
    }

    &__img-container {
        background-color: #ffd6cf;
        border-radius: 4px;

        @media(min-width: 1024px) {
            width: 186px;
            height: 186px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media(max-width: 1024px) {
            padding: 10px 20px;
            height: 115px;
            width: 115px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
    }

    &__image {
        width: 80%;
        height: 80%;
        mix-blend-mode: multiply;

        @media(max-width: 1024px) {
            width: auto;
            height: auto;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 10px;

        @media screen and (min-width: 1024px) {
            margin-bottom: 60px;
        }
    }

    &__name {
        @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);

        @media (max-width: 1024px) {
            @include fontStyle($fontFamily-default, 14px, 500, 15px, 0px, $color-primary);
        }
    }

    &__tag {
        background-color: #FF7314;
        padding: 3px 8px;
        max-width: 84px;
        border-radius: 2px;
        margin-top: 13px;

        @media screen and (max-width: 1024px) {
            border-radius: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        span {
            @include fontStyle($font-family-barlow, 14px, 500, 18px, 0px, #FFFFFF);
            margin-left: 6px;

            @media screen and (max-width: 1024px) {
                @include fontStyle($font-family-barlow, 11px, 500, 18px, 0px, #FFFFFF);

            }
        }
    }

    &__price {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .offer {
            @include fontStyle($fontFamily-default, 24px, 500, 21px, 0px, $color-primary);

            @media (max-width: 767px) {
                @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, $color-primary);
            }
        }

        .mrp {
            @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, rgba($color-primary, 0.7));
            text-decoration: line-through;

            @media (max-width: 767px) {
                @include fontStyle($fontFamily-default, 14px, 500, 18px, 0px, rgba($color-primary, 0.7));
            }
        }
    }

    &__delivery {
        @include fontStyle($fontFamily-default, 14px, 600, 18px, 0px, #4C9F66);

        @media (max-width: 767px) {
            @include fontStyle($fontFamily-default, 12px, 600, 14px, 0px, #4C9F66);
        }
    }
}